<template>
  <BaseCard
    title="datos del producto"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <ProductForm
      ref="product-form"
      v-model="product"
    />
  </BaseCard>
</template>

<script>
import ProductsApi from '@/api/products-api'
import ProductForm from '@/components/products/form/ProductForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, ProductForm },
  data() {
    return {
      loading: false,
      product: {},
    }
  },
  computed: {
    productForm() {
      return this.$refs['product-form']
    },
  },
  mounted() {
    this.loadProduct()
  },
  methods: {
    async loadProduct() {
      this.loading = true
      try {
        const response = await ProductsApi.get(this.$route.params.id)
        this.product = response.data
        this.product.product_category = this.product.category
        this.product.attribs = this.product.attrValues
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.productForm.validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        if (this.productForm.isImageRemoved()) {
          await ProductsApi.deleteProductImage(this.$route.params.id)
        }

        await ProductsApi.edit(this.$route.params.id, this.product)
        await this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
